<template>
    <!--哈工精密版本-->
    <div class="company-profile">
        <div class="bg-pic" v-if="obj.image" :style="{backgroundImage:'url(/images/website/' + obj.image + ')'}">
            <div class="txt-box">
                <div class="txt-box-title">关于{{obj.name}}</div>
                <div class="txt-box-brief">{{obj.slog}}</div>
            </div>
        </div>
        <div class="about">
            <div class="about-list">
                <div class="list-title">关于我们</div>
                <div class="list-subtitle">/ Who We Are /</div>
                <div class="list-brief" v-html="obj.description"></div>
            </div>
        </div>
        <public-footer />
    </div>

    <!--国检计量版本-->
    <!--<div class="company-profile">
        <div class="bg-pic" v-if="obj.image" :style="{backgroundImage:'url(/images/website/' + obj.image + ')'}">
            <div class="txt-box">
                <div class="txt-box-title">关于国检计量</div>
                <div class="txt-box-brief">{{obj.slog}}</div>
            </div>
        </div>
        <div class="about">
            <div class="about-list">
                <div class="list-title">关于我们</div>
                <div class="list-subtitle">/ Who We Are /</div>
                <div class="list-brief">
                    <p style="margin-bottom: 10px;">深圳国检计量测试技术有限公司专业从事仪器仪表/测量系统校准检测、产品检验检测、非标定制研发的专业计量技术机构；公司成立于2008年，位于深圳南山西丽，拥有独立的校准检测实验室和合法的第三方公正地位；已经通过中国合格评定国家认可委员会(CNAS)的认可，认可证书号为CNAS-L5667 ，符合LAC-MRA承认和认可，完全满足ISO/IEC17025和ISO9000体系的要求。</p>
                    <p style="margin-bottom: 10px;">深圳市高新技术企业，深圳市计量测试学会理事单位，中国计量测试学会会员单位，粤港大湾区创新设计产业联盟会员单位，中国实验室联合协会常务理事单位；</p>
                    <p style="margin-bottom: 10px;">国家检测行业标准起草参与单位，国检云技术研发中心，哈工大产业联盟单位。</p>
                    <p style="margin-bottom: 10px;">公司校准实验室拥有各类先进的标准计量器具，工程师素质高、经验丰富，还聘请了多名经验丰富的专家、教授做技术顾问。认可的校准能力范围涉及：几何量、热工、力学、电学、电磁、无线电、理化、光学、时间频率等十大计量学领域。</p>
                    <p style="margin-bottom: 10px;">公司的检测中心拥有强大的测试能力，已建立具备六大功能的9个专业实验室，主要检测仪器1000多台，专业测试人员达300人，覆盖电子电气、5G通讯、无线电高频、汽车材料及零部件、精密量测、有害物质、儿童玩具等领域。</p>
                    <p style="margin-bottom: 10px;">国检计量校准检测服务涵盖全国大部分区域，除华南总部外，国检计量已在华东、华中、西南、西北设立分实验室。此外国检计量还提供仪器销售、维修和管理咨询服务。</p>
                    <p>国检计量秉承：科学、公正、及时、准确、高效的服务方针，提倡诚恳务实的态度、专业领先的技术为客户提供完善的技术服务和产品服务，我们愿与您携手共进，成为您信赖的合作伙伴！</p>
                </div>
            </div>
        </div>
    </div>-->
</template>

<script>
    import publicFooter from '@/components/common/PublicFooter'

    export default {
        name: "CompanyProfile",
        components: {
            publicFooter,
        },
        data(){
            return{
                obj:{},
            }
        },
        created(){
            this.getData();
        },
        methods:{
            async getData(){
                try {
                    const {data} = await this.$axios('/api/companyprofile');
                    if(data.code){
                        this.obj = data.data[0];
                    }
                }catch (e) {
                    //
                }
            },
        },
    }
</script>

<style scoped>
    .company-profile{
        background-color: #f5f5f5;
    }
    .company-profile .bg-pic{
        width: 100%;
        height: 650px;
        /*background-image: url("/imgs/about_banner.jpg");*/
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }
    .company-profile .bg-pic .txt-box{
        position: absolute;
        width: 100%;
        color: #ffffff;
        top: 180px;
        text-align: center;
    }
    .company-profile .bg-pic .txt-box .txt-box-title{
        font-size: 50px;
        font-weight: 700;
        line-height: 80px;
        margin-bottom: 20px;
    }
    .company-profile .bg-pic .txt-box .txt-box-brief{
        font-size: 28px;
    }

    /*下面描述*/
    .about{
        width: 1000px;
        margin: 0 auto;
        padding: 25px 0;
        box-sizing: border-box;
    }
    .about-list{
        background-color: #ffffff;
        text-align: center;
        padding: 25px;
        box-sizing: border-box;
    }
    .about-list:first-child{
        margin-bottom: 25px;
    }
    .list-title{
        display: inline-block;
        font-size: 44px;
        background: -webkit-linear-gradient(left, #15EDED , #029CF5);
        background: -o-linear-gradient(right, #15EDED, #029CF5);
        background: -moz-linear-gradient(right, #15EDED, #029CF5);
        background: linear-gradient(to right, #15EDED , #029CF5);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .list-subtitle{
        margin-top: 8px;
        font-size: 24px;
        font-weight: lighter;
        color: rgba(0,0,0,0.35);
        line-height: 18px;
    }
    .list-brief{
        margin: 35px auto 25px;
        width: 920px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(0,0,0,1);
        line-height: 30px;
        text-align: justify;
        text-indent: 40px;
    }
</style>